export default class ShopCreateForm {
  constructor(data) {
    this.name = data?.name
    this.address = data?.address
    this.isActive = data?.isActive
  }
  static create(data) {
    return new ShopCreateForm(data)
  }
}
